<template>
  <div>
    <h3>
      Park Search
      <hr />
    </h3>
    <div v-if="!loading">
      <div class="row mb-3">
        <div class="col-md-6 col-sm-12">
          <label for="parkFilter">Park</label>
          <select
            class="form-control form-control-lg"
            id="parkFilter"
            v-model="filter.locationId"
            @change="filterByPark"
          >
            <option :value="''" aria-placeholder="Select Park...">
              Select Park...
            </option>
            <option
              v-for="park in locations"
              :key="park.id"
              :value="park.id"
              aria-placeholder="Select Park..."
            >
              {{ park.longName }}
            </option>
          </select>
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="regionFilter">Region</label>
          <select
            class="form-control form-control-lg"
            id="regionFilter"
            v-model="filter.regionId"
            @change="filterByRegion"
          >
            <option :value="''" aria-placeholder="Select Region...">
              Select Region...
            </option>
            <option
              v-for="region in regions"
              :key="region.id"
              :value="region.id"
              aria-placeholder="Select Region..."
            >
              {{ region.longName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-primary float-left mb-3"
            type="button"
            @click="addPark"
            :disabled="!checkPermission('ParkManagementAddNewPark')"
          >
            ADD NEW PARK
            <i class="fa fa-plus-circle" />
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Parks</div>
        </div>
        <div class="card-body">
          <div class="d-flex mb-2">
            <div class="align-self-center">Items Per Page:</div>
            <div class="ml-2">
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                aria-label="Items Per Page"
              ></b-form-select>
            </div>
          </div>
          <b-table
            ref="table"
            striped
            :fields="fields"
            :items="tableLocations"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            small
            class="mt-4"
            bordered
            sort-icon-left
          >
            <template v-slot:cell(id)="data">
              <button
                class="btn btn-primary btn-sm mr-2"
                @click="view(data.item.id)"
              >
                View
              </button>
              <button
                class="btn btn-primary btn-sm mr-2"
                @click="modify(data.item.id)"
              >
                Modify
              </button>
            </template>
          </b-table>
          <div class="d-flex">
            <div class="mr-auto">
              Showing
              {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
              {{ perPage * currentPage - perPage + perPageDisplay }} of
              {{ totalRows }} entries
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminParkSearch",
  title: "Admin - Park Search",
  mixins: [checkPermissionMixin],
  data() {
    return {
      loading: false,
      filter: {
        locationId: "",
        regionId: ""
      },
      locations: [],
      currentPage: 1,
      totalRows: 25,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 10,
      searchableStatues: [
        { name: "Yes", value: true },
        { name: "No", value: false }
      ],
      fields: [
        { key: "name", sortable: true, label: "Park Name" },
        {
          key: "region",
          sortable: true,
          label: "Park Region"
        },
        { key: "address", sortable: true, label: "Park Address" },
        { key: "id", label: "Actions/Options", class: "actionsColumn" }
      ]
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      const service = new AdminLocationService(this.tenantId);
      const locations = service.getLocations();
      const regions = service.getRegions();
      await Promise.allSettled([locations, regions]).then(responses => {
        if (
          responses[0].value.statusCode === "Success" &&
          responses[1].value.statusCode === "Success"
        ) {
          this.locations = responses[0].value.data.filter(loc =>
            this.checkLocationPermission("ParkManagementParkSearch", loc.id)
          );
          this.regions = responses[1].value.regions;
          this.totalRows = this.locations.length;
          this.perPageDisplay =
            this.totalRows < this.perPageDisplay
              ? this.totalRows
              : this.perPageDisplay;
          this.loading = false;
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    filterByPark() {
      this.filter.regionId = "";
    },
    filterByRegion() {
      this.filter.locationId = "";
    },
    view(id) {
      this.$router.push(`/admin/park-detail/${id}?v=true`).catch(() => {});
    },
    modify(id) {
      this.$router.push(`/admin/park-detail/${id}?v=false`).catch(() => {});
    },
    addPark() {
      this.$router.push(`/admin/add-new-park`).catch(() => {});
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    tableLocations() {
      const tableLocations = [];
      this.locations.forEach(x => {
        tableLocations.push({
          id: x.id,
          name: x.longName,
          region: x.region.longName,
          regionId: x.region.id,
          address: `${x.street1} ${x.street2 ?? ""} 
                    ${x.city}, ${x.state} ${x.zipcode}`
        });
      });
      return tableLocations.filter(x => {
        if (this.filter.locationId) {
          return x.id === this.filter.locationId;
        } else if (this.filter.regionId) {
          return x.regionId === this.filter.regionId;
        } else {
          return x;
        }
      });
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style>
.actionsColumn {
  width: 300px !important;
}
</style>
